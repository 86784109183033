/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { UnespCoreMessageService } from 'src/libs/unesp-core'
import { ActivatedRoute } from '@angular/router'
import { Andamento } from 'src/app/models/andamento'
import { AndamentoService } from 'src/app/services/andamento.service'
import { MatDialog } from '@angular/material/dialog'
import { ConfirmDialogModel, ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component'
import { ArquivoViewerDialogComponent } from '../../arquivo-viewer-dialog/arquivo-viewer-dialog.component'
import { DownloadService } from '../../../services/download.service'
import { ConcursoService } from 'src/app/services/concurso.service'

@Component({
  selector: 'app-cadastro-andamento',
  templateUrl: './cadastro-andamento.component.html',
  styleUrls: ['./cadastro-andamento.component.css'],
})
export class CadastroAndamentoComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatSort) sort!: MatSort

  colunasGrid: string[] = ['id', 'data', 'tipo', 'titulo', 'publicacao', 'recurso', 'action']
  dataSource: MatTableDataSource<Andamento> = new MatTableDataSource()
  tituloConcurso?: string
  idConcurso?: number
  andamento?: Andamento
  cadastro: boolean = false

  constructor(
    private andamentoService: AndamentoService,
    private unespCoreMessageService: UnespCoreMessageService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private downloadService: DownloadService,
    private readonly concursoService: ConcursoService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.idConcurso = params['idConcurso']
      this.initTable()
    })
  }

  private initTable() {
    this.andamentoService.listar(this.idConcurso!).subscribe(andamentos => {
      this.dataSource = new MatTableDataSource(andamentos)
      this.dataSource.sort = this.sort
      this.dataSource.paginator = this.paginator
      if (andamentos.length != 0) {
        this.tituloConcurso = andamentos[0].tituloConcurso
      } else {
        this.concursoService.getTitulo(this.idConcurso).subscribe(resp => {
          this.tituloConcurso = resp.concursoTitulo
        })
      }
    })
  }

  update(andamento?: Andamento) {
    this.cadastro = false
    if (!(typeof andamento === 'undefined')) {
      let idx = this.dataSource.data.findIndex(value => value.id == andamento.id)
      if (idx == -1) {
        // Add row
        let tmp = [andamento, ...this.dataSource.data]
        this.dataSource.data = tmp
      } else {
        // Update row
        for (const item in this.dataSource.data[idx]) {
          ;(this.dataSource.data[idx] as any)[item] = (andamento as any)[item]
        }
      }
    }
  }

  adicionar(): void {
    this.andamento = undefined
    this.cadastro = true
  }

  editar(andamento: Andamento, index: number): void {
    this.andamento = andamento
    this.cadastro = true
  }

  excluir(andamento: Andamento, index: number): void {
    const confirmDialog = new ConfirmDialogModel('Atenção', `Confirma a exclusão do Andamento #${andamento.id}`)
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.andamentoService.excluir(this.idConcurso!, Number(andamento.id)).subscribe(andamentoExcluido => {
          // Delete row
          let tmp: Andamento[] = this.dataSource.data
          tmp.splice(this.dataSource.data.indexOf(andamento), 1)
          this.dataSource.data = tmp
          this.unespCoreMessageService.showMessageSuccess(`Andamento #${andamentoExcluido.id} excluído com sucesso`)
        })
      }
    })
  }

  verAnexo(caminho: string, anexo: string, titulo: string) {
    this.downloadService.arquivoObjectURLPorPath(caminho, anexo).subscribe(url =>
      this.dialog.open(ArquivoViewerDialogComponent, {
        data: { tituloDoArquivo: titulo, urlDoArquivo: url },
        width: '80%',
        height: '90%',
      })
    )
  }

  concluir(andamento: Andamento, index: number): void {
    const confirmDialog = new ConfirmDialogModel(
      'Atenção',
      `Confirma a conclusão e notificação para o Andamento #${andamento.id}`
    )
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: confirmDialog,
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.andamentoService.concluir(this.idConcurso!, Number(andamento.id)).subscribe(andamentoConcluido => {
          // Update row attribute
          this.dataSource.data.forEach(row => {
            if (row == andamento) {
              row.andamentoConcluido = true
            }
          })
          this.unespCoreMessageService.showMessageSuccess(`Andamento #${andamentoConcluido.id} concluído com sucesso`)
        })
      }
    })
  }

  search(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }
}
