<div *ngIf="userLogin$ | async" fxLayout="column">
  <unesp-core-home style="display: none"></unesp-core-home>
  <div fxLayout="row wrap" fxLayoutAlign="space-between" fxLayoutAlign="center">
    <mat-card appearance="outlined" class="card">
      <mat-card-header>
        <mat-card-title> Olá, {{ user?.nomeUsuario }} </mat-card-title>
        <mat-card-subtitle>Utilize o menu para acessar as funcionalidades</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <hr />
        <table>
          <tr>
            <td>Perfil:</td>
            <td>{{ perfil }}</td>
          </tr>
          <tr *ngIf="user?.campus">
            <td>Unidade:</td>
            <td>
              {{ user?.campus }}<span *ngIf="user?.sigla"> - {{ user?.sigla }}</span>
            </td>
          </tr>
          <tr *ngIf="user?.nomeLotacaoOficial">
            <td>Lotação:</td>
            <td>{{ user?.nomeLotacaoOficial }}</td>
          </tr>
        </table>
      </mat-card-content>
    </mat-card>
  </div>
</div>
