import { environment } from 'src/environments/environment'

export const authConfig = {
  issuer: environment.oauthAuthorize,
  redirectUri: environment.baseUrlRedirect,
  clientId: environment.clientId,
  responseType: 'code',
  scope: 'openid profile email offline_access',
  showDebugInformation: true,
  requireHttps: false, // Allow HTTP requests
  oidc: true,
  useSilentRefresh: true,
  tokenEndpoint: environment.oauthAuthorize,
}
