/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ElementRef } from '@angular/core'
import { FormControl } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { Anexo } from 'src/app/models/anexo'
import { DownloadService } from 'src/app/services/download.service'

import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'

export interface AnexoLI extends Anexo {
  marked: boolean
  downloading?: boolean
  objectURL?: string
  erro?: boolean
}

@Component({
  selector: 'app-inscricao-anexos',
  templateUrl: './inscricao-anexos.component.html',
  styleUrls: ['./inscricao-anexos.component.scss'],
})
export class InscricaoAnexosComponent implements OnInit, OnChanges {
  @Input() anexos: Anexo[] = []

  @ViewChild('anexoIframe') anexoIframe?: ElementRef
  fileNotFound: boolean = false

  id?: number
  todosFC: FormControl = new FormControl('')
  opcoesFC: FormControl = new FormControl([] as string[])
  printDisabled: boolean = true
  folhadeRosto: boolean = false
  arquivoUnico: boolean = false
  anexoList: AnexoLI[] = []
  anexoSelecionado: number = -1
  markedCounter: number = 0
  downloadingCounter: number = 0

  constructor(private downloadService: DownloadService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id']
    })
    this.todosFC.valueChanges.subscribe(value => {
      if (value) {
        this.checkAll(value == 'todos')
        this.todosFC.reset()
      }
    })
    this.opcoesFC.valueChanges.subscribe(value => {
      const arr = value as String[]
      this.folhadeRosto = arr.includes('rosto')
      this.arquivoUnico = arr.includes('unico')
      if (this.anexoSelecionado == -1) this.mostrarArquivo(0)
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.printDisabled = this.anexos.length == 0

    for (const arq of this.anexos) {
      let anexoLi: AnexoLI = { ...arq, marked: false }
      this.anexoList.push(anexoLi)
    }

    // Só fazer download e exibe o arquivo após o usuário selecionar algum
    // if(!this.printDisabled) this.mostrarArquivo(0)
  }

  private checkAll(b: boolean) {
    this.markedCounter = 0

    if (!b) {
      //Desmarca todos
      for (const anx of this.anexoList) anx.marked = false
      return
    }

    for (const anx of this.anexoList) {
      //Marca todos
      if (anx.erro) continue //Ignora os com flag de erro

      anx.marked = true
      this.markedCounter++

      if (anx.downloading || anx.objectURL) continue //downloadAnexo() já foi chamado para este anexo

      this.downloadAnexo(anx, -1)
    }
  }

  mostrarArquivo(idx: number) {
    if (this.anexoSelecionado == idx) return //já é o anexo selecionado

    const anx = this.anexoList[idx]

    if (anx.downloading) return //Está em download

    if (anx.erro || anx.objectURL) {
      //Já foi feito o download antes
      this.anexoSelecionado = idx
      this.setIframe(anx.objectURL)
    } else this.downloadAnexo(anx, idx)
  }

  //Faz o download do anexo, open_idx = -1 para não abrir no iframe ao terminar
  //open_idx = indice do anexo para abri-lo no iframe ao terminar
  private downloadAnexo(anx: AnexoLI, open_idx: number) {
    anx.downloading = true
    this.downloadingCounter++
    this.downloadService.arquivoObjectURLPorPath(anx.path.toString(), anx.fileName).subscribe(url => {
      this.downloadingCounter--
      anx.downloading = false
      if (open_idx != -1) {
        this.setIframe(url)
        this.anexoSelecionado = open_idx
      }
      if (url) anx.objectURL = url
      else {
        anx.erro = true
        if (anx.marked) {
          anx.marked = false
          this.markedCounter--
        }
      }
    })
  }

  // Abre a url no iframe, no caso de string vazia oculta o iframe
  // e exibe o div 404 no lugar
  private setIframe(url?: string) {
    if (url) {
      this.anexoIframe!.nativeElement.setAttribute!('src', url)
      this.fileNotFound = false
      this.anexoIframe!.nativeElement.style.display = ''
    } else {
      this.anexoIframe!.nativeElement.style.display = 'none'
      this.fileNotFound = true
    }
  }

  arquivoToggle(idx: number) {
    if (!this.arquivoUnico || this.printDisabled || this.anexoList[idx].erro) return

    this.anexoList[idx].marked = !this.anexoList[idx].marked

    if (this.anexoList[idx].marked) this.markedCounter++
    else this.markedCounter--
  }

  private async mergeAndPrintPdf() {
    if (!this.arquivoUnico) {
      //Se estiver imprimindo o selecionado [1]
      this.checkAll(false)
      this.anexoList[this.anexoSelecionado].marked = true
    }

    const mergedPdf = await PDFDocument.create()
    const timesRomanFont = await mergedPdf.embedFont(StandardFonts.TimesRoman)

    for (const anx of this.anexoList) {
      if (anx.fileName.includes('.pdf') || anx.fileName.includes('.PDF')) {
        if (!anx.marked || anx.erro || !anx.objectURL) continue

        //Adiciona Folha de Rosto
        if (this.folhadeRosto) {
          const page = mergedPdf.addPage()

          if (anx.obrigatorio)
            page.drawText(anx.obrigatorio, {
              x: 50,
              y: page.getSize().height - 50,
              size: 12,
              font: timesRomanFont,
              color: rgb(0, 0, 0),
            })

          page.drawText(anx.nome, {
            x: 50,
            y: page.getSize().height - 70,
            size: 12,
            font: timesRomanFont,
            color: rgb(0, 0, 0),
          })
        }

        //Adiciona o Anexo
        const existingPdfBytes = await fetch(anx.objectURL).then(res => res.arrayBuffer())
        if (existingPdfBytes.byteLength > 0) {
          const pdfDoc = await PDFDocument.load(existingPdfBytes)
          const copiedPages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices())
          copiedPages.forEach((page: any) => mergedPdf.addPage(page))
        }
      }
    }

    if (!this.arquivoUnico)
      //Se estiver imprimindo o selecionado [2]
      this.anexoList[this.anexoSelecionado].marked = false

    const mergedPdfFile = await mergedPdf.save()
    const mergedUrl = window.URL.createObjectURL(new Blob([new Uint8Array(mergedPdfFile)], { type: 'application/pdf' }))
    this.setIframe(mergedUrl)

    //Chamar impressão quando o iframe carregar o pdf criado
    const iframe = this.anexoIframe?.nativeElement
    iframe.onload = () => {
      iframe.contentWindow.print()
      this.anexoSelecionado = -1
      this.printDisabled = false
      iframe.onload = undefined
    }
  }

  imprimir() {
    this.printDisabled = true
    if (!this.arquivoUnico && !this.folhadeRosto) {
      if (this.anexoSelecionado > -1) {
        //Imprimir arquivo selecionado sem folha de rosto ou arquivo gerado
        this.anexoIframe?.nativeElement.contentWindow.print()
      }
      this.printDisabled = false
    } else if (this.arquivoUnico || this.folhadeRosto) {
      this.mergeAndPrintPdf()
    } else {
      this.printDisabled = false
    }
  }

  isPrintDisabled(): boolean {
    return (
      this.printDisabled ||
      this.downloadingCounter > 0 ||
      (this.arquivoUnico ? this.markedCounter == 0 : this.fileNotFound)
    )
  }
}
