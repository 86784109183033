<div fxLayout="column" fxLayoutGap="20px">
  <div fxLayoutAlign.gt-xs="space-between center">
    <h1>Reduções de Taxa do Concurso:</h1>
    <div>
      <button mat-raised-button color="primary" (click)="exportar()"><mat-icon>save_alt</mat-icon> Exportar</button>
    </div>
  </div>
  <h2>{{concursoTitulo}}</h2>

  <div fxLayout.gt-md="row" fxLayout.lt-md="column" fxLayoutGap="10px">
    <mat-form-field appearance="outline" display="inline-block">
      <mat-label>Filtrar</mat-label>
      <mat-select [(value)]="filtro" (valueChange)="selecionar()">
        <mat-option value="TODOS">Todos</mat-option>
        <mat-option value="SOLICITADA">Solicitada</mat-option>
        <mat-option value="NAO_SOLICITADA">Não solicitada</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" display="inline-block">
      <mat-label>Deferimento</mat-label>
      <mat-select [(value)]="filtroDeferido" (valueChange)="selecionar()">
        <mat-option value="TODOS">Todos</mat-option>
        <mat-option value="DEFERIDO">Deferido</mat-option>
        <mat-option value="INDEFERIDO">Indeferido</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <mat-form-field appearance="outline" fxFill>
    <mat-icon matPrefix>search</mat-icon>
    <mat-label>Pesquisar</mat-label>
    <input matInput type="search" placeholder="Digite e pressione ENTER para pesquisar" #input />
  </mat-form-field>

  <div>
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>#</th>
        <td mat-cell *matCellDef="let row">{{ row.id }}</td>
      </ng-container>
      <ng-container matColumnDef="nome">
        <th mat-header-cell *matHeaderCellDef>Nome</th>
        <td mat-cell *matCellDef="let row">{{ row.nome }}</td>
      </ng-container>
      <ng-container matColumnDef="cpf">
        <th mat-header-cell *matHeaderCellDef>CPF</th>
        <td mat-cell *matCellDef="let row">{{ row.cpf }}</td>
      </ng-container>
      <ng-container matColumnDef="protocolo">
        <th mat-header-cell *matHeaderCellDef>Protocolo</th>
        <td mat-cell *matCellDef="let row">{{ row.protocolo }}</td>
      </ng-container>
      <ng-container matColumnDef="valor">
        <th mat-header-cell *matHeaderCellDef>Valor</th>
        <td mat-cell *matCellDef="let row">{{ row.valor | number : '.2-2' }}</td>
      </ng-container>
      <ng-container matColumnDef="solicitada">
        <th mat-header-cell *matHeaderCellDef>Solicitada</th>
        <td mat-cell *matCellDef="let row">{{ row.solicitada ? 'Sim' : 'Não' }}</td>
      </ng-container>
      <ng-container matColumnDef="reducao_aprovado">
        <th mat-header-cell *matHeaderCellDef>Deferimento</th>
        <td mat-cell *matCellDef="let row">{{ row.deferimento }}</td>
      </ng-container>
      <ng-container matColumnDef="motivo">
        <th mat-header-cell *matHeaderCellDef>Motivo</th>
        <td mat-cell *matCellDef="let row">{{ row.motivo }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="colunasGrid"></tr>
      <tr mat-row *matRowDef="let row; columns: colunasGrid"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="8">
          <span *ngIf="input.value">Nenhum registro encontrado para a pesquisa: "{{ input.value }}"</span>
          <span *ngIf="!input.value">Nenhum registro disponível</span>
        </td>
      </tr>
    </table>
    <mat-paginator
      [length]="totalElements"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100]"
      showFirstLastButtons></mat-paginator>
  </div>
</div>
