import { Injectable } from '@angular/core'
import { OAuthService, OAuthEvent } from 'angular-oauth2-oidc'
import { authConfig } from '../config/auth-config'
import { BehaviorSubject, interval, Subscription } from 'rxjs'
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private refreshSubscription?: Subscription
  private readonly refreshInterval = 5 * 60 * 1000 // Refresh 5 minutes before token expiration
  private tokenRefreshed = new BehaviorSubject<boolean>(false)

  constructor(private oauthService: OAuthService, private router: Router) {
    this.configure()
  }

  private configure() {
    this.oauthService.configure(authConfig)
    this.oauthService.loadDiscoveryDocumentAndTryLogin().then(() => {
      if (this.isLoggedIn) {
        this.startTokenRefresh()
      }
    })

    // Listen for logout or token expiration events
    this.oauthService.events.subscribe((event: OAuthEvent) => {
      if (event.type === 'token_expires' || event.type === 'logout') {
        this.stopTokenRefresh()
      }
    })
  }

  login() {
    this.oauthService.initCodeFlow()
  }

  logout() {
    this.oauthService.logOut()
    this.stopTokenRefresh()
  }

  get isLoggedIn(): boolean {
    return this.oauthService.hasValidAccessToken()
  }

  get oauthEvent() {
    return this.oauthService.events
  }

  get getAccessToken() {
    return this.oauthService.getAccessToken()
  }

  get getIdToken() {
    return this.oauthService.getIdToken()
  }

  get getTokenExpiration() {
    return this.oauthService.getAccessTokenExpiration()
  }

  get getGrantedScopes() {
    return this.oauthService.getGrantedScopes()
  }

  get getRefreshToken() {
    return this.oauthService.getRefreshToken()
  }

  /**
   * Observable to notify token refresh events
   */
  get tokenRefreshed$() {
    return this.tokenRefreshed.asObservable()
  }

  private startTokenRefresh() {
    this.refreshSubscription = interval(this.refreshInterval).subscribe(() => {
      this.refreshTokens()
    })
  }

  private stopTokenRefresh() {
    this.refreshSubscription?.unsubscribe()
    this.refreshSubscription = undefined
  }

  private refreshTokens() {
    this.oauthService
      .refreshToken()
      .then(() => {
        this.tokenRefreshed.next(true)
      })
      .catch(error => {
        console.error('Error refreshing token', error)
        this.logout()
      })
  }
}
