<div fxLayout="column" fxLayoutGap="20px">
  <h1>Provas do Concurso:</h1>
  <h2>{{ concursoTitulo }}</h2>

  <mat-form-field appearance="outline">
    <mat-label>{{ addProvaLabel }}</mat-label>
    <mat-select [formControl]="adicionarProvaFC" data-seletor="adicionar">
      <mat-option *ngFor="let enum of tipoProva | keyvalue" [value]="enum.key">
        {{ enum.value }}
      </mat-option>
    </mat-select>
    <mat-icon matSuffix style="opacity: 60%">note_add</mat-icon>
  </mat-form-field>

  <div>
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="tipoProva">
        <th mat-header-cell *matHeaderCellDef>Provas</th>
        <td mat-cell *matCellDef="let row">{{ tipoProvaStr(row.tipoProva) }}</td>
      </ng-container>
      
      <ng-container matColumnDef="fase">
        <th mat-header-cell *matHeaderCellDef>Fase de Avaliação</th>
        <td mat-cell *matCellDef="let row">
          <div class="fases-provas">
            <mat-select [(value)]="row.provaFase" (selectionChange)="atualizarFase(row, $event.value)"
            placeholder="Selecionar fase">
              <mat-option *ngFor="let fase of listaFases" [value]="fase">
                {{ fase }}
              </mat-option>
            </mat-select>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="acoes">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <button mat-stroked-button color="basic" routerLink="/concurso/{{ row.idConcurso }}/prova/{{ row.tipoProva }}/criterios">
            Critérios
          </button>
          &nbsp;
          <button mat-stroked-button color="basic" routerLink="/concurso/{{ row.idConcurso }}/prova/{{ row.tipoProva }}/notas">
            Notas
          </button>
          &nbsp;
          <button mat-stroked-button color="basic" data-btn="excluir" (click)="excluir(row)" [disabled]="row.temNotas">
            Excluir
          </button>
          &nbsp;
          <button mat-stroked-button color="basic" data-btn="divulgar" (click)="divulgar(row)"
            [disabled]="row.publicado || !row.temNotas || row.qntNotasParciais > 0" style="width: 7em">
            {{ row.publicado ? 'Divulgado' : 'Divulgar' }}
          </button>
          &nbsp;
          <button mat-stroked-button color="basic" data-btn="concluir" (click)="concluir(row)"
            [disabled]="!row.publicado || row.concluido || row.qntNotasParciais > 0" style="width: 7em">
            {{ row.concluido ? 'Concluído' : 'Concluir' }}
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="colunasGrid"></tr>
      <tr mat-row *matRowDef="let row; columns: colunasGrid" [attr.data-linha-prova]="row.tipoProva"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="8">
          <span>Nenhuma prova adicionada</span>
        </td>
      </tr>
    </table>
  </div>

  <br />
  <div fxLayoutAlign="end center">
    <button
      mat-raised-button
      color="primary"
      data-btn="resultado"
      (click)="gerarResultadoFinal()"
      [disabled]="!temProvaConcluida">
      Gerar Quadro Resultado Final
    </button>
  </div>
</div>
