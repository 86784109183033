/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Inject, Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { UnespCoreEnvironment } from 'src/libs/unesp-core'
import { identity, Observable } from 'rxjs'
import { Concurso } from '../models/concurso'
import { ErrorHandlerService } from './error-handler.service'
import { Campus } from '../models/campus'
import { ConcursoTitulo } from '../models/concurso-titulo'

@Injectable({
  providedIn: 'root',
})
export class ConcursoService {
  private baseUrl: string

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService,
    @Inject('environment') env: UnespCoreEnvironment
  ) {
    this.baseUrl = env.baseUrlApi
  }

  gravar(form: Concurso): Observable<any> {
    return this.http
      .post<string>(`${this.baseUrl}/concurso`, form)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  get(id: number): Observable<Concurso> {
    return this.http
      .get<Concurso>(`${this.baseUrl}/concurso/${id}`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  getTitulo(id: number | undefined): Observable<ConcursoTitulo> {
    console.log(id)
    return this.http
      .get<ConcursoTitulo>(`${this.baseUrl}/concurso/${id}/titulo`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  atualizar(id: number, form: Concurso): Observable<Concurso> {
    return this.http
      .put<Concurso>(`${this.baseUrl}/concurso/${id}`, form)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  listar(
    search = '',
    page = 0,
    size = 10,
    filtroCacpSelecionado = 'TODOS',
    filtroCcptSelecionado = 'TODOS',
    filtroAndamentoSelecionado = '',
    filtroTipoConcursoSelecionado = ''
  ): Observable<any> {
    return this.http
      .get<any>(`${this.baseUrl}/concurso`, {
        params: new HttpParams()
          .set('search', search)
          .set('page', page.toString())
          .set('size', size.toString())
          .set('filtroCacp', filtroCacpSelecionado)
          .set('filtro', filtroCcptSelecionado)
          .set('filtroAndamento', filtroAndamentoSelecionado)
          .set('tipoConcurso', filtroTipoConcursoSelecionado),
      })
      .pipe(identity, this.errorHandlerService.catchError())
  }

  excluir(id: number): Observable<Concurso> {
    return this.http
      .delete<Concurso>(`${this.baseUrl}/concurso/${id}`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  camposHabilitados(): Observable<Campus[]> {
    return this.http
      .get<Campus[]>(`${this.baseUrl}/concurso/unidades-habilitadas`)
      .pipe(identity, this.errorHandlerService.catchError())
  }
}
