/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { getSituacaoInscricaoByDescription, SituacaoInscricao } from './../../../enums/situacao-inscricao'
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { MatPaginator } from '@angular/material/paginator'
import { fromEvent, debounceTime, distinctUntilChanged, tap } from 'rxjs'
import { filter } from 'rxjs/operators'
import { ListagensDiversasService } from '../../../services/listagens-diversas.service'
import { ActivatedRoute } from '@angular/router'
import { ListagemSituacao } from 'src/app/models/listagem-situacao'
import { DatePipe } from '@angular/common'
import { UnespCoreMessageService } from 'src/libs/unesp-core'
import { ConcursoService } from 'src/app/services/concurso.service'

@Component({
  selector: 'app-situacao-inscricao',
  templateUrl: './situacao-inscricao.component.html',
  styleUrls: ['./situacao-inscricao.component.scss'],
})
export class SituacaoInscricaoComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator
  @ViewChild(MatSort) sort!: MatSort
  @ViewChild('input') input!: ElementRef

  totalElements: number = 0

  colunasGrid: string[] = [
    'id',
    'nome',
    'cpf',
    'protocolo',
    'pago',
    'situacao',
    'motivo',
    'autor',
    'data',
    'concluida',
    'action',
  ]
  dataSource: MatTableDataSource<ListagemSituacao> = new MatTableDataSource()

  idConcurso?: number
  concursoTitulo?: string
  filtro: string = ''

  datepipe: DatePipe = new DatePipe('pt-BR')

  optSituacao = SituacaoInscricao
  dialogRef: any
  identificacaoFC: any
  mascaraVazio: string = ''

  constructor(
    private listagensDiversasService: ListagensDiversasService,
    private route: ActivatedRoute,
    private unespCoreMessageService: UnespCoreMessageService,
    private readonly concursoService: ConcursoService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.idConcurso = params['id']
      this.getData()
    })
  }

  ngAfterViewInit() {
    // server-side search
    fromEvent<KeyboardEvent>(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        filter((e: KeyboardEvent) => e.keyCode === 13),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0
          this.getData()
        })
      )
      .subscribe()

    // on paginate events, load a new page
    this.paginator?.page.pipe(tap(() => this.getData())).subscribe()
  }

  concluir(row: ListagemSituacao) {
    this.listagensDiversasService.concluirSituacaoInscricao(this.idConcurso, row.id).subscribe(value => {
      if (value) {
        row.concluida = true
        this.unespCoreMessageService.showMessageSuccess(`${row.nome}, situação concluída com sucesso`)
      } else {
        this.unespCoreMessageService.showMessageError(`Falha ao concluir, ${row.nome}`)
      }
    })
  }

  salvar(row: ListagemSituacao) {
    if (row.situacao == 'NAO_CONFIRMADA') {
      this.unespCoreMessageService.showMessageWarning(`Informe a situação do candidato ${row.nome}`)
    } else if (row.situacao == 'INDEFERIDA' && !row.motivo) {
      this.unespCoreMessageService.showMessageError(`Informe o motivo para do Indeferimento de ${row.nome}`)
    } else {
      this.listagensDiversasService
        .salvarSituacaoInscricao(this.idConcurso, row.id, row.situacao, row.motivo ?? '')
        .subscribe(value => {
          if (value) {
            row.autor = value.autor
            row.motivo = value.motivo
            row.data = value.data
            this.unespCoreMessageService.showMessageSuccess(`${row.nome}, situação registrada com sucesso`)
          } else {
            this.unespCoreMessageService.showMessageError(`Falha ao registrar, ${row.nome}`)
          }
        })
    }
  }

  private getData() {
    this.listagensDiversasService
      .getSituacaoInscricao(
        this.idConcurso!,
        this.input?.nativeElement.value,
        this.filtro,
        this.paginator.pageIndex,
        this.paginator.pageSize
      )
      .subscribe(data => {
        let inscricoes = data['content'] as ListagemSituacao[]
        this.dataSource = new MatTableDataSource(
          inscricoes.map(value => {
            value.situacao = getSituacaoInscricaoByDescription(value.situacao)
            return value
          })
        )
        this.dataSource.sort = this.sort
        this.totalElements = data['totalElements']
      })
      this.concursoService.getTitulo(this.idConcurso).subscribe(resp => {
        this.concursoTitulo = resp.concursoTitulo
      })
  }

  exportar() {
    this.listagensDiversasService
      .getSituacaoInscricao(this.idConcurso!, this.input?.nativeElement.value, this.filtro, 0, this.totalElements)
      .subscribe(data => {
        let recursos: ListagemSituacao[] = data['content']
        let content = `"#","NOME","CPF","PROTOCOLO","PAGO","SITUAÇÃO","MOTIVO","AUTOR","DATA"\r\n`
        recursos.forEach(row => {
          content += `"${row.id}","${row.nome}","${row.cpf}","${row.protocolo}","${row.pago ? 'Sim' : 'Não'}","${
            row.situacao
          }","${row.motivo == null ? '' : row.motivo}","${row.autor == null ? '' : row.autor}","${
            row.data == null ? '' : this.datepipe.transform(row.data, 'dd/MM/YYYY HH:mm:ss')
          }"\r\n`
        })
        let binaryData = []
        binaryData.push(content)
        let downloadLink = document.createElement('a')
        downloadLink.setAttribute('download', `listagem/situacao-${this.idConcurso}.csv`)
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'attachment/csv;charset=utf-8' }))
        document.body.appendChild(downloadLink)
        downloadLink.click()
        downloadLink.remove()
      })
  }

  selecionar(): void {
    this.getData()
  }
}
