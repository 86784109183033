/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { UnespCoreMenuNavigationService, UnespCoreMessageService, UnespCoreUsuario } from 'src/libs/unesp-core'
import { UsuarioLogin } from 'src/app/models/usuario-login'
import { Observable, Subscription } from 'rxjs'
import { UserLoginService } from 'src/app/services/usuario-login.service'
import { getMenu } from 'src/app/menu'
import { AuthService } from 'src/app/services/auth.service'
import { OAuthEvent } from 'angular-oauth2-oidc'

@Component({
  selector: 'app-auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.css'],
})
export class AuthLoginComponent implements OnInit, OnDestroy {
  user?: UsuarioLogin | null
  private userSubscription!: Subscription
  perfil: string = ''
  recarregarMenu: number = 0

  constructor(
    private userLoginService: UserLoginService,
    private unespCoreMessageService: UnespCoreMessageService,
    private router: Router,
    private unespCoreMenuNavigationService: UnespCoreMenuNavigationService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.authService.oauthEvent.subscribe(async (event: OAuthEvent) => {
      if (event.type === 'token_received') {
        const user = await this.userLoginService.doLoginToken(this.authService.getAccessToken)
        this.getPerfil()
        if (user) {
          this.recarregarMenu = 0
        }
        this.recarregarMenu += 1
        if (this.recarregarMenu === 1) {
          setTimeout(() => {
            this.unespCoreMenuNavigationService.itensMenuSource.next(
              getMenu(this.userLoginService.getUser() === null, this.userLoginService.getUser()?.roles ?? [])
            )
          }, 500)
        }
        // if (user) {
        //   this.router.navigate(['/'])
        // }
      }
    })
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe()
    }
  }

  private getPerfil(): void {
    if (this.user) {
      this.user.roles.forEach(role => {
        if (role !== '**' && role !== '!!') {
          this.perfil += role + ', '
        }
      })
      if (this.perfil.length > 0) {
        this.perfil = this.perfil.slice(0, -2)
      }
    }
  }

  recuperarSenha(): void {
    this.unespCoreMessageService.showMessageWarning('Recurso indisponível')
  }

  get userLogin$(): Observable<UsuarioLogin | null> {
    return this.userLoginService.user$
  }
}
