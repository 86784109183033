/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

export enum TipoConcursoDTA {
  PT = 'Professor Doutor',
  LD = 'Título de Livre-Docência',
}

export enum TipoConcurso {
  DEM = 'Docente de Ensino Médio',
  COLAB = 'Professor Colaborador',
  RDIDP = 'Professor Efetivo - RDIDP',
  RTC = 'Professor Efetivo - RTC',
  RTP = 'Professor Efetivo - RTP',
  SUBST = 'Professor Substituto',
  SUBv2 = 'Professor Substituto (anexos via sistema)',
  TMP = 'Professor temporário - CTI',
  PT = 'Professor Doutor',
  TECADM = 'Técnico Administrativo',
  LD = 'Título de Livre-Docência',
  PESQ4 = 'Pesquisador IV',
  PESQ3 = 'Pesquisador III',
}

export const TipoConcursoLabel = new Map<string, string>([
  ['DEM', 'Docente de Ensino Médio'],
  ['COLAB', 'Professor Colaborador'],
  ['RDIDP', 'Professor Efetivo - RDIDP'],
  ['RTC', 'Professor Efetivo - RTC'],
  ['RTP', 'Professor Efetivo - RTP'],
  ['SUBST', 'Professor Substituto'],
  ['SUBv2', 'Professor Substituto (anexos via sistema)'],
  ['TMP', 'Professor temporário - CTI'],
  ['PT', 'Professor Doutor'],
  ['TECADM', 'Técnico Administrativo'],
  ['LD', 'Título de Livre-Docência'],
  ['PESQ4', 'Pesquisador IV'],
  ['PESQ3', 'Pesquisador III'],
])
