/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { UnespCoreEnvironment } from 'src/libs/unesp-core'
import { identity, Observable } from 'rxjs'

import { ConcursoComProvas } from '../models/concurso-com-provas'
import { ConcursoProva } from '../models/concurso-prova'
import { ErrorHandlerService } from './error-handler.service'

@Injectable({
  providedIn: 'root',
})
export class ConcursoProvaService {
  private baseUrl: string

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService,
    @Inject('environment') env: UnespCoreEnvironment
  ) {
    this.baseUrl = `${env.baseUrlApi}/concurso-prova`
  }

  listar(idConcurso: number | string): Observable<ConcursoComProvas> {
    return this.http
      .get<ConcursoComProvas>(`${this.baseUrl}/${idConcurso}`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  gravar(prova: ConcursoProva): Observable<ConcursoProva> {
    return this.http
      .post<ConcursoProva>(`${this.baseUrl}/${prova.idConcurso}`, prova)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  excluir(idConcurso: number | string, tipoProva: string): Observable<number> {
    return this.http
      .delete<number>(`${this.baseUrl}/${idConcurso}/${tipoProva}`)
      .pipe(identity, this.errorHandlerService.catchError())
  }

  publicar(idConcurso: number | string, tipoProva: string): Observable<number> {
    return this.http
      .patch<number>(`${this.baseUrl}/${idConcurso}/${tipoProva}/publicado`, {})
      .pipe(identity, this.errorHandlerService.catchError())
  }

  concluir(idConcurso: number | string, tipoProva: string): Observable<number> {
    return this.http
      .patch<number>(`${this.baseUrl}/${idConcurso}/${tipoProva}/concluido`, {})
      .pipe(identity, this.errorHandlerService.catchError())
  }

  atualizarFase(idConcurso: number, tipoProva: string, novaFase: string) {
    return this.http
      .put(`${this.baseUrl}/${idConcurso}/${tipoProva}/fase`, { provaFase: novaFase })
      .pipe(identity, this.errorHandlerService.catchError());
  }

  gerarResultadoFinal(idConcurso: number): void {
    let headers = new HttpHeaders()
    headers = headers.set('Accept', 'application/pdf')
    this.http
      .get<any>(`${this.baseUrl}/${idConcurso}/resultado-final`, {
        headers: headers,
        responseType: 'blob' as 'json',
        observe: 'response',
      })
      .pipe(identity, this.errorHandlerService.catchError())
      .subscribe(res => {
        let url = window.URL.createObjectURL(res.body)
        let downloadLink = document.createElement('a')
        downloadLink.href = url
        downloadLink.download = `${idConcurso}_quadro_resuldado_final`
        downloadLink.click()
        window.URL.revokeObjectURL(url)
        downloadLink.remove()
      })
  }
}
