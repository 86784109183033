<div fxLayout="column" fxLayoutGap="20px">
  <div fxLayoutAlign.gt-md="space-between center">
    <h1>Concursos</h1>
    <div *ngIf="canModify()">
      <button mat-raised-button color="primary" routerLink="/concurso/cadastro">
        <mat-icon>add</mat-icon> Adicionar
      </button>
      &nbsp;
      <button mat-raised-button color="primary" (click)="exportar()"><mat-icon>save_alt</mat-icon> Exportar</button>
    </div>
    <div *ngIf="isCCPT()">
      <mat-form-field appearance="outline">
        <mat-label>Exibir</mat-label>
        <mat-select name="filtroCcpt" [(value)]="filtroCcptSelecionado" (selectionChange)="getData()">
          <mat-option *ngFor="let type of filtroCcpt | keyvalue" [value]="type.key">
            {{ type.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="isCACP()">
      <mat-form-field appearance="outline">
        <mat-label>Filtrar</mat-label>
        <mat-select name="filtroCacp" [(value)]="filtroCacpSelecionado" (selectionChange)="getData()">
          <mat-option *ngFor="let type of filtroCacp | keyvalue" [value]="type.key">
            {{ type.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div fxLayout.lt-md="column" fxLayoutGap="20px">
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Filtrar por etapa atual</mat-label>
      <mat-select name="filtroEtapa" [(value)]="filtroAndamentoSelecionado" (selectionChange)="getData()">
        <mat-option value="">Todas as etapas</mat-option>
        <mat-option *ngFor="let enum of tipoAndamento | keyvalue" [value]="enum.key">
          {{ enum.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" fxFlex>
      <mat-label>Tipo de concurso</mat-label>
      <mat-select name="filtroTipoConcurso" [(value)]="filtroTipoConcursoSelecionado" (selectionChange)="getData()">
        <mat-option value="">Todos os Tipos de Concurso</mat-option>
        <mat-option
          *ngFor="let enum of (showTipoConcursoDTA() ? tipoConcursoDTA : tipoConcurso) | keyvalue"
          [value]="enum.key">
          {{ enum.value }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <mat-form-field appearance="outline" fxFill>
    <mat-icon matPrefix>search</mat-icon>
    <mat-label>Pesquisar</mat-label>
    <input matInput type="search" placeholder="Digite e pressione ENTER para pesquisar" #input />
  </mat-form-field>

  <div>
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
        <td mat-cell *matCellDef="let row">{{ row.id }}</td>
      </ng-container>
      <ng-container matColumnDef="campus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Unidade</th>
        <td mat-cell *matCellDef="let row">{{ row.campus }}</td>
      </ng-container>
      <ng-container matColumnDef="processo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Processo</th>
        <td mat-cell *matCellDef="let row">{{ row.processo }}</td>
      </ng-container>
      <ng-container matColumnDef="titulo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Título</th>
        <td mat-cell *matCellDef="let row">{{ row.titulo }}</td>
      </ng-container>
      <ng-container matColumnDef="inscricao">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Inscrição</th>
        <td mat-cell *matCellDef="let row">
          {{ row.inicio | date : 'dd/MM/yyyy' }} a {{ row.fim | date : 'dd/MM/yyyy' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="tipo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo</th>
        <td mat-cell *matCellDef="let row">{{ getTipo(row.tipo) }}</td>
      </ng-container>
      <ng-container matColumnDef="andamento">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Etapa</th>
        <td mat-cell *matCellDef="let row">{{ row.andamento }}<br />{{ row.andamentoData | date : 'dd/MM/yyyy' }}</td>
      </ng-container>
      <ng-container matColumnDef="action" *ngIf="canOpenOptions()">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <button
            mat-stroked-button
            color="basic"
            routerLink="/inscricao/{{ row.id }}/{{ row.concurso }}"
            title="Inscrições">
            {{ row.quantidadeInscritos }}
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="action2" *ngIf="canOpenOptions()">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <button mat-icon-button color="basic" [matMenuTriggerFor]="menu" title="Opções">
            <mat-icon>settings</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button
              *ngIf="canModify()"
              mat-menu-item
              color="basic"
              routerLink="/concurso/{{ row.id }}/andamento"
              title="Andamento">
              <mat-icon>list</mat-icon><span>Andamento</span>
            </button>
            <button
              *ngIf="mostrarProvasBtn(row)"
              mat-menu-item
              color="basic"
              routerLink="/concurso/{{ row.id }}/provas"
              title="Provas">
              <mat-icon>assignment</mat-icon><span>Provas</span>
            </button>
            <button
              *ngIf="canModify()"
              mat-menu-item
              color="basic"
              routerLink="/concurso/cadastro/{{ row.id }}"
              title="Editar">
              <mat-icon>edit</mat-icon><span>Editar</span>
            </button>
            <button *ngIf="canModify()" mat-menu-item color="warn" (click)="excluir(row)" title="Excluir">
              <mat-icon>delete</mat-icon><span>Excluir</span>
            </button>
            <mat-divider *ngIf="canModify()"></mat-divider>
            <button *ngIf="canModify()" mat-menu-item routerLink="/listagem-documentos/{{ row.id }}">
              <mat-icon>summarize</mat-icon><span>Documentos</span>
            </button>
            <button *ngIf="canModify()" mat-menu-item routerLink="/listagem-situacao-inscricao/{{ row.id }}">
              <mat-icon>summarize</mat-icon><span>Situação</span>
            </button>
            <button mat-menu-item routerLink="/listagem-pagamento/{{ row.id }}" *ngIf="row.valorInscricao !== 0">
              <mat-icon>summarize</mat-icon><span>Pagamento</span>
            </button>
            <button mat-menu-item routerLink="/listagem-reducao-taxa/{{ row.id }}" *ngIf="row.valorInscricao !== 0">
              <mat-icon>summarize</mat-icon><span>Redução</span>
            </button>
            <button mat-menu-item routerLink="/listagem-classificacao-inscricao/{{ row.id }}">
              <mat-icon>summarize</mat-icon><span>Classificação</span>
            </button>
            <button mat-menu-item routerLink="/listagem-status-documentacao/{{ row.id }}">
              <mat-icon>summarize</mat-icon><span>Status da Documentação de inscrição</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>
      <ng-container *ngIf="isCACP(); then perfilCacp; else perfilCcpt"></ng-container>
      <ng-template #perfilCcpt>
        <ng-container matColumnDef="action" *ngIf="!canOpenOptions()">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <button
              *ngIf="filtroCcptSelecionado === 'TODOS'"
              mat-stroked-button
              color="basic"
              routerLink="/inscricao/{{ row.id }}"
              title="Inscrições">
              {{ row.quantidadeInscritos }}
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="action2" *ngIf="!canOpenOptions()">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <button
              *ngIf="filtroCcptSelecionado !== 'TODOS'"
              mat-icon-button
              color="basic"
              routerLink="/inscricao/{{ row.id }}/pago/sim"
              title="Inscrições">
              {{ row.quantidadeInscritos }}
            </button>
          </td>
        </ng-container>
      </ng-template>
      <ng-template #perfilCacp>
        <ng-container matColumnDef="action" *ngIf="!canOpenOptions()">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <button
              *ngIf="filtroCacpSelecionado === 'TODOS'"
              mat-stroked-button
              color="basic"
              routerLink="/inscricao/{{ row.id }}"
              title="Inscrições">
              {{ row.quantidadeInscritos }}
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="action2" *ngIf="!canOpenOptions()">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <button
              *ngIf="filtroCacpSelecionado !== 'TODOS'"
              mat-icon-button
              color="basic"
              routerLink="/inscricao/{{ row.id }}/pago/sim"
              title="Inscrições">
              {{ row.quantidadeInscritos }}
            </button>
          </td>
        </ng-container>
      </ng-template>

      <tr mat-header-row *matHeaderRowDef="colunasGrid"></tr>
      <tr mat-row *matRowDef="let row; columns: colunasGrid"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="9">
          <span *ngIf="input.value">Nenhum registro encontrado para a pesquisa: "{{ input.value }}"</span>
          <span *ngIf="!input.value">Nenhum registro disponível</span>
        </td>
      </tr>
    </table>
    <mat-paginator
      [length]="totalElements"
      [pageSize]="20"
      [pageSizeOptions]="[10, 20, 50, 100]"
      showFirstLastButtons></mat-paginator>
  </div>
</div>
