<div fxLayout="column" fxLayoutGap="20px">
  <h1>Documentos Inscrições do Concurso:</h1>
  <h2>{{concursoTitulo}}</h2>

  <mat-form-field appearance="outline">
    <mat-label>Documento</mat-label>
    <mat-select [formControl]="tipoDocumentoFc">
      <mat-option *ngFor="let enum of tipoDocumento | keyvalue" [value]="enum.key">
        {{ enum.value }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline" fxFill>
    <mat-icon matPrefix>search</mat-icon>
    <mat-label>Pesquisar</mat-label>
    <input matInput type="search" placeholder="Digite e pressione ENTER para pesquisar" #input />
  </mat-form-field>

  <div>
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>#</th>
        <td mat-cell *matCellDef="let row">{{ row.id }}</td>
      </ng-container>
      <ng-container matColumnDef="concurso">
        <th mat-header-cell *matHeaderCellDef>Concurso</th>
        <td mat-cell *matCellDef="let row">
          <p>{{ row.campus }}</p>
          <p>{{ row.tituloConcurso }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="inscricao">
        <th mat-header-cell *matHeaderCellDef>Inscrição</th>
        <td mat-cell *matCellDef="let row">
          <p>{{ row.data | date : 'dd/MM/yyyy HH:mm:ss' }}</p>
          <p *ngIf="!isDepartamento">{{ row.protocolo }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="candidato">
        <th mat-header-cell *matHeaderCellDef>Candidato</th>
        <td mat-cell *matCellDef="let row">
          <p>{{ row.nome }}</p>
          <p><b>CPF</b> {{ row.cpf }}</p>
          <p><b>RG</b> {{ row.rg }}</p>
        </td>
      </ng-container>
      <ng-container matColumnDef="contato">
        <th mat-header-cell *matHeaderCellDef>Contato</th>
        <td mat-cell *matCellDef="let row">
          <p>{{ row.email }}</p>
          <p>{{ row.celular }}</p>
          <p>{{ row.telefone }}</p>
        </td>
      </ng-container>

      <ng-container matColumnDef="documento">
        <th mat-header-cell *matHeaderCellDef>{{ tipoDocumentoStr(documentoSelecionado) }}</th>
        <td mat-cell *matCellDef="let row; let i = index">
          <div *ngIf="documentoAnexado(row); then editarDocumentoMenu; else adicionarDocumentoBtn"></div>
          <ng-template #adicionarDocumentoBtn>
            <button mat-icon-button color="basic" (click)="enviarDocumento(row)" title="Adicionar">
              <mat-icon>add</mat-icon>
            </button>
          </ng-template>
          <ng-template #editarDocumentoMenu>
            <button mat-icon-button color="basic" [matMenuTriggerFor]="menu" title="Documento">
              <mat-icon>description</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item color="basic" (click)="visualizarDocumento(row)" title="Visualizar">
                <mat-icon>file_open</mat-icon><span>Visualizar</span>
              </button>
              <button mat-menu-item color="basic" (click)="enviarDocumento(row)" title="Substituir">
                <mat-icon>refresh</mat-icon><span>Substituir</span>
              </button>
              <button mat-menu-item color="warn" (click)="excluirDocumento(row, i)" title="Excluir">
                <mat-icon>delete</mat-icon><span>Excluir</span>
              </button>
            </mat-menu>
          </ng-template>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="colunasGrid"></tr>
      <tr mat-row *matRowDef="let row; columns: colunasGrid"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="8">
          <span *ngIf="input.value">Nenhum registro encontrado para a pesquisa: "{{ input.value }}"</span>
          <span *ngIf="!input.value">Nenhum registro disponível</span>
        </td>
      </tr>
    </table>
    <mat-paginator
      [length]="totalElements"
      [pageSize]="10"
      [pageSizeOptions]="[10, 20, 50, 100]"
      showFirstLastButtons></mat-paginator>

    <input type="file" #fileInput (change)="selecionaArquivo($event)" style="display: none" accept="application/pdf" />
  </div>
</div>
