/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { MatPaginator } from '@angular/material/paginator'
import { fromEvent, debounceTime, distinctUntilChanged, tap } from 'rxjs'
import { filter } from 'rxjs/operators'
import { ListagensDiversasService } from '../../../services/listagens-diversas.service'
import { ActivatedRoute } from '@angular/router'
import { DatePipe } from '@angular/common'
import { ListagemReducao } from '../../../models/listagem-reducao'
import { ConcursoService } from 'src/app/services/concurso.service'

@Component({
  selector: 'app-reducao-taxa',
  templateUrl: './reducao-taxa.component.html',
  styleUrls: ['./reducao-taxa.component.scss'],
})
export class ReducaoTaxaComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator
  @ViewChild(MatSort) sort!: MatSort
  @ViewChild('input') input!: ElementRef
  totalElements: number = 0

  colunasGrid: string[] = ['id', 'nome', 'cpf', 'protocolo', 'valor', 'solicitada', 'reducao_aprovado', 'motivo']

  dataSource: MatTableDataSource<ListagemReducao> = new MatTableDataSource()
  idConcurso?: number
  concursoTitulo?: string
  filtro: string = ''
  filtroDeferido: string = ''
  datepipe: DatePipe = new DatePipe('pt-BR')

  constructor(
    private readonly listagensDiversasService: ListagensDiversasService, 
    private readonly route: ActivatedRoute,
    private readonly concursoService: ConcursoService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.idConcurso = params['id']
      this.getData()
    })
  }

  ngAfterViewInit() {
    // server-side search
    fromEvent<KeyboardEvent>(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        filter((e: KeyboardEvent) => e.keyCode === 13),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0
          this.getData()
        })
      )
      .subscribe()

    // on paginate events, load a new page
    this.paginator?.page.pipe(tap(() => this.getData())).subscribe()
  }

  private getData() {
    this.listagensDiversasService
      .getReducao(
        this.idConcurso!,
        this.input?.nativeElement.value,
        this.filtro,
        this.filtroDeferido,
        this.paginator.pageIndex,
        this.paginator.pageSize
      )
      .subscribe(data => {
        let content: ListagemReducao[] = data['content']

        for (let reducao of content) {
          if (!reducao.solicitada) reducao.deferimento = '---'
          else if (!reducao.concluida) reducao.deferimento = 'Aguardando Análise'
          else if (reducao.reducao_aprovado) reducao.deferimento = 'Pedido deferido'
          else reducao.deferimento = 'Pedido Indeferido'
        }

        this.dataSource = new MatTableDataSource(content)
        this.dataSource.sort = this.sort
        this.totalElements = data['totalElements']
      })
      this.concursoService.getTitulo(this.idConcurso).subscribe(resp => {
        this.concursoTitulo = resp.concursoTitulo
      })
  }

  exportar() {
    this.listagensDiversasService
      .getReducao(
        this.idConcurso!,
        this.input?.nativeElement.value,
        this.filtro,
        this.filtroDeferido,
        0,
        this.totalElements
      )
      .subscribe(data => {
        let recursos: ListagemReducao[] = data['content']
        let content = `"#","NOME","CPF","PROTOCOLO","VALOR","SOLICITADA","APROVADA","MOTIVO"\r\n`
        recursos.forEach(row => {
          content += `"${row.id}","${row.nome}","${row.cpf}","${row.protocolo}","${row.valor}","${
            row.solicitada ? 'Sim' : 'Não'
          }","${row.solicitada ? (row.reducao_aprovado ? 'Deferido' : 'Indeferido') : 'Deferimento inexistente'}","${
            row.motivo == null ? '' : row.motivo
          }"\r\n`
        })
        let binaryData = []
        binaryData.push(content)
        let downloadLink = document.createElement('a')
        downloadLink.setAttribute('download', `listagem-reducao-taxa-${this.idConcurso}.csv`)
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'attachment/csv;charset=utf-8;' }))
        document.body.appendChild(downloadLink)
        downloadLink.click()
        downloadLink.remove()
      })
  }

  selecionar(): void {
    this.getData()
  }
}
