/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit } from '@angular/core'
import { UnespCoreAuthService } from '../../services/unesp-core-auth/unesp-core-auth.service'
import { ActivatedRoute, Router } from '@angular/router'
import { UnespCoreMenuNavigationService } from '../../services/unesp-core-menu-navigation/unesp-core-menu-navigation.service'
import { UnespCoreUsuario } from '../../interfaces/unesp-core-usuario'
import { UnespCoreUserService } from '../../services/unesp-core-user'
import { Observable } from 'rxjs'
import { OnDestroy } from '@angular/core'
import { Subscription } from 'rxjs'
import { AuthService } from 'src/app/services/auth.service'

/**
 * @description
 *
 * Este componente representa a página home da aplicação.
 *
 */
@Component({
  selector: 'unesp-core-home',
  templateUrl: './unesp-core-home.component.html',
  styleUrls: ['./unesp-core-home.component.css'],
})
export class UnespCoreHomeComponent implements OnInit, OnDestroy {
  nomeUsuario: string = ''

  private userSubscription!: Subscription

  constructor(
    private unespCoreAuthService: UnespCoreAuthService,
    private unespCoreUserService: UnespCoreUserService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public unespCoreMenuNavigationService: UnespCoreMenuNavigationService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      const authorizationCode = params['code']
      if (authorizationCode) {
        // this.unespCoreAuthService.retrieveToken(authorizationCode)
        this.router.navigate([''])
      } else {
        const tryAuth = params['tryAuth']

        if (tryAuth) {
          // if (!this.unespCoreUserService.getUser()) {
          this.login()
          // } else {
          // this.router.navigate([''])
          // }
        }
      }
    })
    // this.userSubscription = this.unespCoreUserService.user$.subscribe(usuario => {
    //   this.nomeUsuario = usuario ? ', ' + usuario.nomeUsuario + '!' : '.'
    // })
  }

  ngOnDestroy(): void {
    // if (this.userSubscription) {
    //   this.userSubscription.unsubscribe()
    // }
  }

  get user$(): Observable<UnespCoreUsuario | null> {
    return this.unespCoreUserService.user$
  }

  login(): void {
    this.authService.login()
    // window.location.href = this.unespCoreAuthService.getLoginUrl()
  }

  logout(): void {
    this.authService.logout()
    // this.unespCoreAuthService.logout('Usuário desconectado com sucesso')
    // this.unespCoreMenuNavigationService.appDrawer?.close()
  }
}
