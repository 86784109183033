/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { HttpErrorResponse } from '@angular/common/http'
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { InscricaoService } from 'src/app/services/inscricao.service'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { MatPaginator } from '@angular/material/paginator'
import { ActivatedRoute } from '@angular/router'
import { UnespCoreAuthService, UnespCoreMessageService } from 'src/libs/unesp-core'
import { fromEvent, debounceTime, distinctUntilChanged, tap } from 'rxjs'
import { filter } from 'rxjs/operators'
import { DatePipe } from '@angular/common'
import { TipoDocumento } from 'src/app/enums/tipo-documento'
import { InscricaoComDocumentos } from 'src/app/models/inscricao-com-documentos'
import { FormControl } from '@angular/forms'
import { InscricaoDocumento } from 'src/app/models/inscricao-documento'
import { InscricaoDocumentoService } from 'src/app/services/inscricao-documento.service'
import { ConfirmDialogModel, ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component'
import { ArquivoUploadStatus } from 'src/app/models/arquivo-upload-status.model'
import { ArquivoUploadService } from 'src/app/services/arquivo-upload.service'
import { DownloadService } from 'src/app/services/download.service'
import { ArquivoViewerDialogComponent } from '../../arquivo-viewer-dialog/arquivo-viewer-dialog.component'
import { ConcursoService } from 'src/app/services/concurso.service'

@Component({
  selector: 'app-lista-inscricoes-documento',
  templateUrl: './lista-inscricoes-documento.component.html',
  styleUrls: ['./lista-inscricoes-documento.component.css'],
})
export class ListaInscricoesDocumentoComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator
  @ViewChild(MatSort) sort!: MatSort
  @ViewChild('input') input!: ElementRef
  @ViewChild('fileInput') fileInput!: ElementRef

  totalElements: number = 0

  tipoDocumento = TipoDocumento
  tipoDocumentoFc: FormControl = new FormControl('PROVA_ESCRITA')
  documentoSelecionado: string = 'PROVA_ESCRITA'

  colunasGrid: string[] = ['id', 'concurso', 'inscricao', 'candidato', 'contato', 'documento']
  dataSource: MatTableDataSource<InscricaoComDocumentos> = new MatTableDataSource()

  inscricaoUpload: InscricaoComDocumentos | null = null
  documentoUpload: string | null = null

  idConcurso? = undefined
  concursoTitulo?: string

  datepipe: DatePipe = new DatePipe('pt-BR')

  isDepartamento: boolean = false

  constructor(
    private inscricaoService: InscricaoService,
    private route: ActivatedRoute,
    private unespCoreAuthService: UnespCoreAuthService,
    private unespCoreMessageService: UnespCoreMessageService,
    private inscricaoDocumentoService: InscricaoDocumentoService,
    private arquivoUploadService: ArquivoUploadService,
    private downloadService: DownloadService,
    private dialog: MatDialog,
    private readonly concursoService: ConcursoService
  ) {}

  ngOnInit(): void {
    this.isDepartamento = this.unespCoreAuthService.hasPermissionBasedRoles(['DEPARTAMENTO'])
    this.route.params.subscribe(params => {
      this.idConcurso = params['idConcurso']
      this.getData()
    })
    this.tipoDocumentoFc.valueChanges.subscribe(value => {
      this.documentoSelecionado = value
      this.getData()
    })
  }

  ngAfterViewInit() {
    // server-side search
    fromEvent<KeyboardEvent>(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        filter((e: KeyboardEvent) => e.keyCode === 13),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0
          this.getData()
        })
      )
      .subscribe()

    // on paginate events, load a new page
    this.paginator?.page.pipe(tap(() => this.getData())).subscribe()
  }

  private getData() {
    if (!this.documentoSelecionado) return
    this.inscricaoService
      .listarDocumentos(
        this.idConcurso!,
        'TODOS',
        'TODOS',
        this.input?.nativeElement.value,
        this.paginator.pageIndex,
        this.paginator.pageSize
      )
      .subscribe(data => {
        this.dataSource = new MatTableDataSource(data['content'])
        this.dataSource.sort = this.sort
        this.totalElements = data['totalElements']
      })
      this.concursoService.getTitulo(this.idConcurso).subscribe(resp => {
        this.concursoTitulo = resp.concursoTitulo
      })
  }

  private extrairInscricaoDocumento(
    inscricao: InscricaoComDocumentos,
    tipoDoc?: string
  ): InscricaoDocumento | undefined {
    const tipo = this.documentoSelecionado!
    const idx = inscricao.inscricaoDocumentos.findIndex(value => value.tipoDocumento == tipo)
    if (idx == -1) return
    else return inscricao.inscricaoDocumentos[idx]
  }

  tipoDocumentoStr(tipo?: string): string {
    if (tipo) return (TipoDocumento as any)[tipo]
    else return ''
  }

  documentoAnexado(inscricao: InscricaoComDocumentos): boolean {
    let documento = this.extrairInscricaoDocumento(inscricao)
    return !!documento
  }

  visualizarDocumento(inscricao: InscricaoComDocumentos) {
    let doc = this.extrairInscricaoDocumento(inscricao)
    if (!doc) return

    const downloadPath = `inscricaodocumento/${doc.id}`

    const titulo = `Inscrição #${inscricao.id}: ${this.tipoDocumentoStr(this.documentoSelecionado)}`

    this.downloadService.arquivoObjectURLPorPath(downloadPath, doc.arquivo).subscribe(url =>
      this.dialog.open(ArquivoViewerDialogComponent, {
        data: { tituloDoArquivo: titulo, urlDoArquivo: url },
        width: '80%',
        height: '90%',
      })
    )
  }

  enviarDocumento(inscricao: InscricaoComDocumentos) {
    this.inscricaoUpload = inscricao
    this.documentoUpload = this.documentoSelecionado!

    this.fileInput?.nativeElement.click()
  }

  excluirDocumento(inscricao: InscricaoComDocumentos, idx: number) {
    const doc = this.extrairInscricaoDocumento(inscricao)
    if (!doc) return

    const data = new ConfirmDialogModel('Atenção', `Confirma a exclusão do Documento #${doc.id}`)
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data })

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.inscricaoDocumentoService.excluir(doc.id).subscribe(concurso => {
          let tmp = this.dataSource.data
          tmp[idx].inscricaoDocumentos.splice(0, 1)
          this.dataSource.data = tmp
          this.unespCoreMessageService.showMessageSuccess(`Documento ${doc.id} excluído com sucesso`)
        })
      }
    })
  }

  selecionaArquivo(e: Event) {
    const target = e.target as HTMLInputElement
    const files = target.files as FileList

    const file = files.item(0)

    if (file == null) return

    if (file.type != 'application/pdf') {
      this.unespCoreMessageService.showMessageError('Formato de arquivo invalido, use: .pdf')
      return
    }

    if (this.inscricaoUpload != null && this.documentoUpload != null)
      this.upload_async(file, this.inscricaoUpload, this.documentoUpload)
  }

  private async upload_async(
    file: File,
    inscricao: InscricaoComDocumentos,
    tipoDoc: string
  ): Promise<ArquivoUploadStatus> {
    const documentoUploadPath = 'inscricaodocumento/'
    const doc = this.extrairInscricaoDocumento(inscricao, tipoDoc)
    let docId = doc ? doc.id : 0

    let formData = new FormData()
    formData.append('inscricaoId', inscricao.id.toString())
    formData.append('tipoDocumento', tipoDoc)
    formData.append('arquivo', file)

    var promise = new Promise<ArquivoUploadStatus>((resolve, reject) => {
      this.arquivoUploadService.arquivoUpload(documentoUploadPath + docId, formData).subscribe({
        next: (r: ArquivoUploadStatus) => {
          const id = r.uploadPath ? parseInt(r.uploadPath.slice(19)) : docId
          let doc: InscricaoDocumento = {
            id,
            idInscricao: inscricao.id,
            tipoDocumento: tipoDoc,
            arquivo: r.uuid! + '.pdf',
            tamanho: r.size,
          }
          this.updateDocumento(doc)
          resolve(r)
        },
        error: err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status == 413) {
              let erro: { mensagemUsuario: string; mensagemDesenvolvedor: string } = err.error
              this.unespCoreMessageService.showMessageError(erro.mensagemUsuario)
            } else {
              this.unespCoreMessageService.showMessageError(err.message)
            }
          } else {
            console.error(err)
            reject()
          }
        },
      })
    })

    const v = await promise
    return v
  }

  private updateDocumento(doc: InscricaoDocumento) {
    let arr = this.dataSource.data
    const idx = arr.findIndex(value => value.id == doc.idInscricao)

    if (idx != -1) {
      arr[idx].inscricaoDocumentos[0] = doc
      this.dataSource.data = arr
    }
  }
}
