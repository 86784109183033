/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

export const TipoProva = {
  ESCRITA: { tipo: 'Escrita', nome: 'Prova Escrita' },
  DIDATICA: { tipo: 'Didática', nome: 'Prova Didática' },
  ARGUICAO: { tipo: 'Arguição', nome: 'Prova de Arguição' },
  PRATICA: { tipo: 'Prática', nome: 'Prova Prática' },
  TITULOS: { tipo: 'Títulos', nome: 'Prova de Títulos' },
  ESPECIFICA: { tipo: 'Específica', nome: 'Prova Específica'},
  PLANO: { tipo: 'Plano de Trabalho', nome: 'Análise do Plano de Trabalho'}
}

export const ProvasArr = ['DIDATICA', 'ESCRITA', 'ARGUICAO', 'TITULOS', 'PRATICA', 'ESPECIFICA', 'PLANO']

export const TipoProvaVersao = {
  ESCRITA0023: { tipo: 'ESCRITA', desc: 'Escrita' },
  DIDATICA0023: { tipo: 'DIDATICA', desc: 'Didática' },  
  ARGUICAO1723: { tipo: 'ARGUICAO', desc: 'Arguição' },
  PRATICA0023: { tipo: 'PRATICA', desc: 'Prática' },
  TITULOS0023: { tipo: 'TITULOS', desc: 'Títulos' },
  ESPECIFICA: { tipo: 'ESPECIFICA', desc: 'Específica'},
  PLANO: { tipo: 'PLANO', desc: 'Análise do Plano de Trabalho'},
}

export interface TipoProvaDetalhe {
  customizavel: boolean
}

export const TipoAndamentoDetalhes = new Map<string, TipoProvaDetalhe>([
  ['ESCRITA', { customizavel: true }],
  ['DIDATICA', { customizavel: true }],
  ['ARGUICAO', { customizavel: true }],
  ['PRATICA', { customizavel: true }],
  ['TITULOS', { customizavel: true }],
  ['ESPECIFICA', { customizavel: true }],
  ['PLANO', { customizavel: true }],
])

export enum ProvaEtapa {
  EDICAO = '1ª - Edição',
  PUBLICADA = '2ª - Publicada',
  CONCLUIDA = '3ª - Concluída',
}
